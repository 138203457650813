import React, { useState, useEffect } from "react";
import "./DailyTasks.css";
import image1 from "./IMG/Group 12.png";
import axios from "axios";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const DailyTasks = ({ setPercentage }) => {
  const [formData, setFormData] = useState({
    morningRemembrances: false,
    eveningPrayers: false,
    tasbeeh: false,
    prayForTheProphet: false,
    crownOfRemembrance: false,
    ThereIsNoPowerNorStrengthSaveWithAllah: false,
    prayForTheProphet100Times: false,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDailyTasks = new FormData();

    const tasks = [
      { task_id: 50, completed: formData.morningRemembrances ? 1 : 0 },
      { task_id: 51, completed: formData.eveningPrayers ? 1 : 0 },
      { task_id: 52, completed: formData.tasbeeh ? 1 : 0 },
      { task_id: 53, completed: formData.prayForTheProphet ? 1 : 0 },
      { task_id: 54, completed: formData.crownOfRemembrance ? 1 : 0 },
      {
        task_id: 55,
        completed: formData.ThereIsNoPowerNorStrengthSaveWithAllah ? 1 : 0,
      },
      { task_id: 56, completed: formData.prayForTheProphet100Times ? 1 : 0 },
    ];
    console.log("Daily Tasks being sent:", tasks);
    tasks.forEach((task, index) => {
      formDailyTasks.append(`tasks[${index}][task_id]`, task.task_id);
      formDailyTasks.append(`tasks[${index}][completed]`, task.completed);
    });

    try {
      const response = await axios.post(
        "https://muslim-dairy.alyoumsa.com/api/tasks/create/5",
        formDailyTasks,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      const totalPercentage = response.data.total_percentage;
      setPercentage(totalPercentage);
      console.log("Response:", response.data);
      alert("Daily Tasks successfully submitted!");
      localStorage.setItem("dailyTasks", JSON.stringify(formData));
    } catch (error) {
      console.error("Error submitting tasks:", error.response || error.message);
      alert("Error submitting tasks. Please try again.");
    }
  };

  useEffect(() => {
    const fetchDailyTasks = async () => {
      try {
        const response = await axios.get(
          "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/5",
          {
            headers: {
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );

        console.log("API Response for DailyTasks:", response.data);

        const tasks = response.data.tasks;
        console.log("Fetched tasks:", tasks);
        const newFormData = { ...formData };

        if (Array.isArray(tasks)) {
          tasks.forEach((task) => {
            switch (task.task_id) {
              case 50:
                newFormData.morningRemembrances = task.completed === 1;
                break;
              case 51:
                newFormData.eveningPrayers = task.completed === 1;
                break;
              case 52:
                newFormData.tasbeeh = task.completed === 1;
                break;
              case 53:
                newFormData.prayForTheProphet = task.completed === 1;
                break;
              case 54:
                newFormData.crownOfRemembrance = task.completed === 1;
                break;
              case 55:
                newFormData.ThereIsNoPowerNorStrengthSaveWithAllah =
                  task.completed === 1;
                break;
              case 56:
                newFormData.prayForTheProphet100Times = task.completed === 1;
                break;
              default:
                break;
            }
          });
          localStorage.setItem("dailyTasks", JSON.stringify(newFormData));
        } else {
          console.error("Tasks is not an array:", tasks);
        }

        setFormData(newFormData);
      } catch (error) {
        console.error(
          "Error fetching daily tasks:",
          error.response || error.message
        );
      }
    };

    const savedTasks = localStorage.getItem("dailyTasks");
    if (savedTasks) {
      setFormData(JSON.parse(savedTasks));
    } else {
      fetchDailyTasks();
    }
  }, []);

  const clearTasks = () => {
    localStorage.removeItem("dailyTasks");
    setFormData({
      morningRemembrances: false,
      eveningPrayers: false,
      tasbeeh: false,
      prayForTheProphet: false,
      crownOfRemembrance: false,
      ThereIsNoPowerNorStrengthSaveWithAllah: false,
      prayForTheProphet100Times: false,
    });
  };

  return (
    <div className="daily-tasks-container">
      <div className="tasks-box">
        <h3 className="tasks-title">الأوراد اليومية</h3>
        <div className="separator-line-daily-task"></div>
        <form onSubmit={handleSubmit}>
          <div className="checkbox-container">
            <div className="tasks-list-left">
              <label>
                <input
                  type="checkbox"
                  name="morningRemembrances"
                  checked={formData.morningRemembrances}
                  onChange={handleChange}
                />
                اذكار الصباح
              </label>
              <label>
                <input
                  type="checkbox"
                  name="eveningPrayers"
                  checked={formData.eveningPrayers}
                  onChange={handleChange}
                />
                أذكار المساء
              </label>
              <label>
                <input
                  type="checkbox"
                  name="tasbeeh"
                  checked={formData.tasbeeh}
                  onChange={handleChange}
                />
                التسبيح 100 مرة
              </label>
              <label>
                <input
                  type="checkbox"
                  name="prayForTheProphet"
                  checked={formData.prayForTheProphet}
                  onChange={handleChange}
                />
                الصلاة على النبي 100 مرة
              </label>
            </div>
            <div className="tasks-list-right" style={{ marginRight: "50px" }}>
              <label>
                <input
                  type="checkbox"
                  name="crownOfRemembrance"
                  checked={formData.crownOfRemembrance}
                  onChange={handleChange}
                />
                تاج الذكر 100 مرة
              </label>
              <label>
                <input
                  type="checkbox"
                  name="ThereIsNoPowerNorStrengthSaveWithAllah"
                  checked={formData.ThereIsNoPowerNorStrengthSaveWithAllah}
                  onChange={handleChange}
                />
                لا حول ولا قوة الا بالله 100 مرة
              </label>
              <label>
                <input
                  type="checkbox"
                  name="prayForTheProphet100Times"
                  checked={formData.prayForTheProphet100Times}
                  onChange={handleChange}
                />
                الصلاة على النبي 100 مرة
              </label>
            </div>
          </div>
          <button type="submit">إرسال</button>
        </form>
      </div>
      <div className="image-box">
        <img src={image1} alt="Quran and Tasbeeh" className="daily-image" />
      </div>
    </div>
  );
};

export default DailyTasks;
