import React, { useState, useEffect } from "react";
import "./FoodSelection.css";
import image1 from "./IMG/Group 15.png";
import axios from "axios";
import Cookies from "js-cookie";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const FoodSelection = ({ setPercentage }) => {
  const [formData, setFormData] = useState({
    salmon: false,
    fish: false,
    shrimp: false,
    Chickenbreast: false,
    okra: false,
    Granola: false,
    meat: false,
    lentils: false,
    Oatsoup: false,
    mulukhiyah: false,
    pumpkin: false,
    honey: false,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const saveToLocalStorage = (data) => {
    localStorage.setItem("foodSelectionData", JSON.stringify(data));
  };

  const loadFromLocalStorage = () => {
    const storedData = localStorage.getItem("foodSelectionData");
    return storedData ? JSON.parse(storedData) : null;
  };

  useEffect(() => {
    const fetchFoodSelection = async () => {
      const localData = loadFromLocalStorage();
      if (localData !== null) {
        setFormData(localData);
        setLoading(false);
        return;
      }

      const token = getCookie("token");
      if (!token) {
        console.error("No authentication token found.");
        setError("Authentication token not found. Please log in.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/11",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const tasks = response.data.tasks;
        console.log("Fetched Food Selection tasks:", tasks);

        const updatedFormData = {
          salmon: tasks.some(
            (task) => task.task_id === 73 && task.completed === 1
          ),
          fish: tasks.some(
            (task) => task.task_id === 74 && task.completed === 1
          ),
          shrimp: tasks.some(
            (task) => task.task_id === 75 && task.completed === 1
          ),
          Chickenbreast: tasks.some(
            (task) => task.task_id === 76 && task.completed === 1
          ),
          okra: tasks.some(
            (task) => task.task_id === 77 && task.completed === 1
          ),
          Granola: tasks.some(
            (task) => task.task_id === 78 && task.completed === 1
          ),
          meat: tasks.some(
            (task) => task.task_id === 79 && task.completed === 1
          ),
          lentils: tasks.some(
            (task) => task.task_id === 80 && task.completed === 1
          ),
          Oatsoup: tasks.some(
            (task) => task.task_id === 81 && task.completed === 1
          ),
          mulukhiyah: tasks.some(
            (task) => task.task_id === 82 && task.completed === 1
          ),
          pumpkin: tasks.some(
            (task) => task.task_id === 83 && task.completed === 1
          ),
          honey: tasks.some(
            (task) => task.task_id === 84 && task.completed === 1
          ),
        };

        setFormData(updatedFormData);
        saveToLocalStorage(updatedFormData);
      } catch (err) {
        console.error("Error fetching Food Selection data:", err);
        setError(
          "Failed to fetch Food Selection data. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchFoodSelection();
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    const updatedData = {
      ...formData,
      [name]: checked,
    };
    setFormData(updatedData);
    saveToLocalStorage(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDailyTasks = new FormData();

    const tasks = [
      { task_id: 73, completed: formData.salmon ? 1 : 0 },
      { task_id: 74, completed: formData.fish ? 1 : 0 },
      { task_id: 75, completed: formData.shrimp ? 1 : 0 },
      { task_id: 76, completed: formData.Chickenbreast ? 1 : 0 },
      { task_id: 77, completed: formData.okra ? 1 : 0 },
      { task_id: 78, completed: formData.Granola ? 1 : 0 },
      { task_id: 79, completed: formData.meat ? 1 : 0 },
      { task_id: 80, completed: formData.lentils ? 1 : 0 },
      { task_id: 81, completed: formData.Oatsoup ? 1 : 0 },
      { task_id: 82, completed: formData.mulukhiyah ? 1 : 0 },
      { task_id: 83, completed: formData.pumpkin ? 1 : 0 },
      { task_id: 84, completed: formData.honey ? 1 : 0 },
    ];

    tasks.forEach((task, index) => {
      formDailyTasks.append(`tasks[${index}][task_id]`, task.task_id);
      formDailyTasks.append(`tasks[${index}][completed]`, task.completed);
    });

    console.log("Submitting the following tasks:", tasks);

    const token = getCookie("token");
    if (!token) {
      console.error("No authentication token found.");
      setError("Authentication token not found. Please log in.");
      return;
    }

    try {
      const response = await axios.post(
        "https://muslim-dairy.alyoumsa.com/api/tasks/create/11",
        formDailyTasks,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const totalPercentage = response.data.total_percentage;
      setPercentage(totalPercentage);
       console.log("Response from server:", response.data);
      alert("Food Selection successfully submitted!");
    } catch (error) {
      console.error("Error submitting tasks:", error.response || error.message);
      setError("Error submitting tasks. Please try again.");
      alert("Error submitting tasks. Please try again.");
    }
  };

  if (loading) {
    return <div className="food-items-container">Loading...</div>;
  }

  return (
    <div className="food-items-container">
      <div className="food-box">
        <h3 className="food-item-title">الاغذية</h3>
        <div className="separator-line-food-items"></div>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="checkbox-container">
            <div className="food-list-left">
              <label>
                <input
                  type="checkbox"
                  name="salmon"
                  checked={formData.salmon}
                  onChange={handleChange}
                />
                سلمون
              </label>
              <label>
                <input
                  type="checkbox"
                  name="fish"
                  checked={formData.fish}
                  onChange={handleChange}
                />
                سمك
              </label>
              <label>
                <input
                  type="checkbox"
                  name="shrimp"
                  checked={formData.shrimp}
                  onChange={handleChange}
                />
                روبيان
              </label>
              <label>
                <input
                  type="checkbox"
                  name="Chickenbreast"
                  checked={formData.Chickenbreast}
                  onChange={handleChange}
                />
                دجاج صدر
              </label>
              <label>
                <input
                  type="checkbox"
                  name="okra"
                  checked={formData.okra}
                  onChange={handleChange}
                />
                بامية
              </label>
              <label>
                <input
                  type="checkbox"
                  name="Granola"
                  checked={formData.Granola}
                  onChange={handleChange}
                />
                غرنولا
              </label>
            </div>
            <div className="food-list-right" style={{ marginRight: "120px" }}>
              <label>
                <input
                  type="checkbox"
                  name="meat"
                  checked={formData.meat}
                  onChange={handleChange}
                />
                لحم
              </label>
              <label>
                <input
                  type="checkbox"
                  name="lentils"
                  checked={formData.lentils}
                  onChange={handleChange}
                />
                شوربة عدس
              </label>
              <label>
                <input
                  type="checkbox"
                  name="Oatsoup"
                  checked={formData.Oatsoup}
                  onChange={handleChange}
                />
                شوربة شوفان
              </label>
              <label>
                <input
                  type="checkbox"
                  name="mulukhiyah"
                  checked={formData.mulukhiyah}
                  onChange={handleChange}
                />
                ملوخية
              </label>
              <label>
                <input
                  type="checkbox"
                  name="pumpkin"
                  checked={formData.pumpkin}
                  onChange={handleChange}
                />
                قرع
              </label>
              <label>
                <input
                  type="checkbox"
                  name="honey"
                  checked={formData.honey}
                  onChange={handleChange}
                />
                عسل
              </label>
            </div>
          </div>
          <button type="submit">إرسال</button>
        </form>
      </div>
      <div className="image-box">
        <img src={image1} alt="food" className="food-image" />
      </div>
    </div>
  );
};

export default FoodSelection;
