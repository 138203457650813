import React, { useState, useEffect } from "react";
import "./Meals.css";
import breakfastImg from "./IMG/Group 9.png";
import lunchImg from "./IMG/Group 10.png";
import dinnerImg from "./IMG/Group 11.png";
import axios from "axios";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const Meals = ({ setPercentage }) => {
  const [meals, setMeals] = useState({
    breakfast: false,
    lunch: false,
    dinner: false,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const saveToLocalStorage = (data) => {
    localStorage.setItem("mealsData", JSON.stringify(data));
  };

  const loadFromLocalStorage = () => {
    const storedData = localStorage.getItem("mealsData");
    return storedData ? JSON.parse(storedData) : null;
  };

  useEffect(() => {
    const fetchMeals = async () => {
      const localData = loadFromLocalStorage();
      if (localData !== null) {
        setMeals(localData);
        console.log("Loaded meals from localStorage:", localData);
        setLoading(false);
        return;
      }

      const token = getCookie("token");
      if (!token) {
        console.error("No authentication token found.");
        setError("Authentication token not found. Please log in.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/4",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const tasks = response.data.tasks;
        console.log("Fetched Meals data from API:", tasks);

        const updatedMeals = {
          breakfast: tasks.some(
            (task) => task.task_id === 47 && task.completed === 1
          ),
          lunch: tasks.some(
            (task) => task.task_id === 48 && task.completed === 1
          ),
          dinner: tasks.some(
            (task) => task.task_id === 49 && task.completed === 1
          ),
        };

        setMeals(updatedMeals);
        saveToLocalStorage(updatedMeals);
      } catch (err) {
        console.error("Error fetching Meals data:", err);
        setError("Failed to fetch Meals data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMeals();
  }, []);


  // const handleSubmit = async (e) => {
  //   setError(null);
  //   setSuccessMessage(null);

  //   const tasks = [
  //     { task_id: 47, completed: meals.breakfast ? 1 : 0 },
  //     { task_id: 48, completed: meals.lunch ? 1 : 0 },
  //     { task_id: 49, completed: meals.dinner ? 1 : 0 },
  //   ];

  //   console.log("Meals data to send:", tasks);
  //   const formData = new URLSearchParams();
  //   tasks.forEach((task, index) => {
  //     formData.append(`tasks[${index}][task_id]`, task.task_id);
  //     formData.append(`tasks[${index}][completed]`, task.completed);
  //   });

  //   const token = getCookie("token");
  //   if (!token) {
  //     console.error("No authentication token found.");
  //     setError("Authentication token not found. Please log in.");
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       "https://muslim-dairy.alyoumsa.com/api/tasks/create/4",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const result = response.data;
  //     if (typeof result.total_percentage !== "undefined") {
  //       const totalPercentage = result.total_percentage;
  //       setPercentage(totalPercentage);
  //       console.log("Meals updated successfully:", result);
  //       alert("Meals successfully submitted!");
  //     } else {
  //       console.warn("total_percentage not found in response.");
  //     }
  //     if (response.ok) {
  //       console.log("Meals submitted successfully:");
  //     }

  //     if (response.status === 200 || response.status === 201) {
  //       console.log("Meals submitted successfully:", response.data);
  //     } else {
  //       console.error("Unexpected response status:", response.status);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting meals:", error.response || error.message);
  //     alert("Error submitting meals:");
  //   }
  // };

  // const handleCheckboxChange = (meal) => {
  //   const updatedMeals = {
  //     ...meals,
  //     [meal]: !meals[meal],
  //   };
  //   setMeals(updatedMeals);
  //   saveToLocalStorage(updatedMeals);
  //   handleSubmit(updatedMeals); 
  // };

  const handleCheckboxChange = (meal) => {
    const updatedMeals = {
      ...meals,
      [meal]: !meals[meal],
    };
    setMeals(updatedMeals);
    saveToLocalStorage(updatedMeals);
    setTimeout(() => {
      handleSubmit(updatedMeals); 
    }, 0); 
  };
  
  const handleSubmit = async (updatedMeals) => {
    setError(null);
    setSuccessMessage(null);
  
    const tasks = [
      { task_id: 47, completed: updatedMeals.breakfast ? 1 : 0 },
      { task_id: 48, completed: updatedMeals.lunch ? 1 : 0 },
      { task_id: 49, completed: updatedMeals.dinner ? 1 : 0 },
    ];
  
    console.log("Meals data to send:", tasks);
    const formData = new URLSearchParams();
    tasks.forEach((task, index) => {
      formData.append(`tasks[${index}][task_id]`, task.task_id);
      formData.append(`tasks[${index}][completed]`, task.completed);
    });
  
    const token = getCookie("token");
    if (!token) {
      console.error("No authentication token found.");
      setError("Authentication token not found. Please log in.");
      return;
    }
  
    try {
      const response = await axios.post(
        "https://muslim-dairy.alyoumsa.com/api/tasks/create/4",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      if (typeof result.total_percentage !== "undefined") {
        const totalPercentage = result.total_percentage;
        setPercentage(totalPercentage); 
        console.log("Meals updated successfully:", result);
        alert("Meals successfully submitted!");
      } else {
        console.warn("total_percentage not found in response.");
      }
  
      if (response.status === 200 || response.status === 201) {
        console.log("Meals submitted successfully:", response.data);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error submitting meals:", error.response || error.message);
      alert("Error submitting meals:");
    }
  };
  

  if (loading) {
    return <div className="meals-container">جارٍ التحميل...</div>;
  }

  return (
    <div className="meals-container">
      <h3 className="meals-title">الطعام</h3>
      <div className="separator-line-meals"></div>

      <div className="meals-grid" >
        <div className="meal-card">
          <img src={dinnerImg} alt="Dinner" className="meal-image" />
          <div className="meal-info">
            <label>
              <input
                type="checkbox"
                checked={meals.dinner}
                onChange={() => handleCheckboxChange("dinner")}
              />
              العشاء
            </label>
            <p>زبادي + فاكهه</p>
          </div>
        </div>

        <div className="meal-card">
          <img src={lunchImg} alt="Lunch" className="meal-image" />
          <div className="meal-info">
            <label>
              <input
                type="checkbox"
                checked={meals.lunch}
                onChange={() => handleCheckboxChange("lunch")}
              />
              الغذاء
            </label>
            <p>حصة قليلة + سنانك</p>
          </div>
        </div>

        <div className="meal-card">
          <img src={breakfastImg} alt="Breakfast" className="meal-image" />
          <div className="meal-info">
            <label>
              <input
                type="checkbox"
                checked={meals.breakfast}
                onChange={() => handleCheckboxChange("breakfast")}
              />
              الفطور
            </label>
            <p>صحي + عصير + سنانك</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meals;
