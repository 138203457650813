import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DailyRoutine.css";
import routineImage from "./IMG/Group 8.png";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const DailyRoutine = ({ setPercentage }) => {
  const [formData, setFormData] = useState({
    morning: {
      faceWash: false,
      sunBlock: false,
    },
    evening: {
      faceWash: false,
      eyeCream: false,
    },
    other: {
      bath: false,
      medicine: false,
      drop: false,
    },
  });

  useEffect(() => {
    const storedData = localStorage.getItem("dailyRoutine");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const newFormData = {
        morning: {
          faceWash: parsedData.morning?.faceWash || false,
          sunBlock: parsedData.morning?.sunBlock || false,
        },
        evening: {
          faceWash: parsedData.evening?.faceWash || false,
          eyeCream: parsedData.evening?.eyeCream || false,
        },
        other: {
          bath: parsedData.other?.bath || false,
          medicine: parsedData.other?.medicine || false,
          drop: parsedData.other?.drop || false,
        },
      };
      setFormData(newFormData);
    } else {
      fetchDailyRoutine();
    }
  }, []);

  const handleCheckboxChange = (section, key) => {
    const newFormData = {
      ...formData,
      [section]: {
        ...formData[section],
        [key]: !formData[section][key],
      },
    };

    setFormData(newFormData);
    localStorage.setItem("dailyRoutine", JSON.stringify(newFormData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tasks = [
      { task_id: 40, completed: formData.morning.faceWash ? 1 : 0 },
      { task_id: 41, completed: formData.morning.sunBlock ? 1 : 0 },
      { task_id: 42, completed: formData.evening.faceWash ? 1 : 0 },
      { task_id: 43, completed: formData.evening.eyeCream ? 1 : 0 },
      { task_id: 44, completed: formData.other.bath ? 1 : 0 },
      { task_id: 45, completed: formData.other.medicine ? 1 : 0 },
      { task_id: 46, completed: formData.other.drop ? 1 : 0 },
    ];

    const formDailyRoutine = new FormData();
    tasks.forEach((task, index) => {
      formDailyRoutine.append(`tasks[${index}][task_id]`, task.task_id);
      formDailyRoutine.append(`tasks[${index}][completed]`, task.completed);
    });

    console.log("Submitting the following tasks:", tasks);
    try {
      const response = await axios.post(
        "https://muslim-dairy.alyoumsa.com/api/tasks/create/3",
        formDailyRoutine,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      const totalPercentage = response.data.total_percentage;
      setPercentage(totalPercentage);
      console.log("Response:", response.data);
      alert("Daily Routine submitted successfully!");
    } catch (error) {
      console.error("Error submitting tasks:", error);
      alert("There was an error submitting the tasks.");
    }
  };

  const fetchDailyRoutine = async () => {
    const token = getCookie("token");
    console.log("Token:", token);
    if (!token) {
      console.error("No authentication token found.");
      alert("Authentication token not found. Please log in.");
      return;
    }

    try {
      const response = await axios.get(
        "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/3",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tasks = response.data.tasks;
      console.log("Fetched Daily Routine tasks from Api:", tasks);

      const updatedFormData = {
        morning: {
          faceWash: tasks.some(
            (task) => task.task_id === 40 && task.completed === 1
          ),
          sunBlock: tasks.some(
            (task) => task.task_id === 41 && task.completed === 1
          ),
        },
        evening: {
          faceWash: tasks.some(
            (task) => task.task_id === 42 && task.completed === 1
          ),
          eyeCream: tasks.some(
            (task) => task.task_id === 43 && task.completed === 1
          ),
        },
        other: {
          bath: tasks.some(
            (task) => task.task_id === 44 && task.completed === 1
          ),
          medicine: tasks.some(
            (task) => task.task_id === 45 && task.completed === 1
          ),
          drop: tasks.some(
            (task) => task.task_id === 46 && task.completed === 1
          ),
        },
      };
      setFormData(updatedFormData);
      localStorage.setItem("dailyRoutine", JSON.stringify(updatedFormData));
    } catch (error) {
      console.error("Error fetching daily routine data:", error);
      console.log("There was an error fetching the daily routine data.");
    }
  };

  return (
    <div className="daily-routine-container">
      <div className="routine-section">
        <h3 className="routine-title">العناية اليومية</h3>
        <div className="separator-line"></div>
        <div className="flex-daily-content">
          <div className="daily-content-details">
            <form onSubmit={handleSubmit}>
              <div className="routine-grid">
                <div className="routine-block">
                  <h4 className="section-title">صباحا</h4>
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "7px",
                      borderRadius: "10px",
                    }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={formData.morning.faceWash}
                        onChange={() =>
                          handleCheckboxChange("morning", "faceWash")
                        }
                      />
                      غسول البشرة
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={formData.morning.sunBlock}
                        onChange={() =>
                          handleCheckboxChange("morning", "sunBlock")
                        }
                      />
                      واقي البشرة
                    </label>
                  </div>
                </div>

                <div className="routine-block">
                  <h4 className="section-title">مساء</h4>
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "7px",
                      borderRadius: "10px",
                    }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={formData.evening.faceWash}
                        onChange={() =>
                          handleCheckboxChange("evening", "faceWash")
                        }
                      />
                      غسول البشرة
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={formData.evening.eyeCream}
                        onChange={() =>
                          handleCheckboxChange("evening", "eyeCream")
                        }
                      />
                      كريم عيون
                    </label>
                  </div>
                </div>
              </div>

              <div className="additional-section">
                <label>
                  <input
                    type="checkbox"
                    checked={formData.other.bath}
                    onChange={() => handleCheckboxChange("other", "bath")}
                  />
                  الاستحمام
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.other.medicine}
                    onChange={() => handleCheckboxChange("other", "medicine")}
                  />
                  أخذ الدواء
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.other.drop}
                    onChange={() => handleCheckboxChange("other", "drop")}
                  />
                  أخذ القطرة
                </label>
              </div>
              <button className="submit-btn" type="submit">
                إرسال
              </button>
            </form>
          </div>
          <div className="image-section">
            <img src={routineImage} alt="Routine" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyRoutine;
