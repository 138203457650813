import React, { useState, useEffect } from "react";
import "./HomePage.css";
import headimage from "./IMG/Group 4.png";
import image1 from "./IMG/Group 5.png";
import image2 from "./IMG/Group 6.png";
import image3 from "./IMG/Group 7.png";
import "font-awesome/css/font-awesome.min.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import RoutineForm from "./RoutineForm";
import PrayerForm from "./PrayerForm";
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const HomePage = ({setPercentage}) => {

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(
        "https://muslim-dairy.alyoumsa.com/api/user/logout",
        {},
        {
          headers: { Authorization: `Bearer ${getCookie("token")}` },
        }
      );
      Cookies.remove("token");
      navigate("/Login", { replace: true });
    } catch (err) {
      console.error("Logout error:", err.message || "Unknown error");
      setError(err.response?.data?.message || "Failed to log out.");
    }
  };
  
  return (
    <div className="main">
      <div className="HeadImg">
        <img src={headimage} alt="" />
      </div>
      <div className="MainTitle">
        <div className="logoutButton">
          <span
            style={{ marginLeft: "5px", textDecoration: "none", color: "#999" }}
            onClick={handleLogout}
          >
            <i
              className="fa fa-user-plus"
              aria-hidden="true"
              style={{ marginLeft: "10px", marginRight: "5px" }}
            ></i>{" "}
            تسجيل الخروج
          </span>
        </div>
        <h1>مخططي اليومي</h1>
      </div>
      <div className="divColor-head"></div>
      <div className="flex-content">
        <div className="right-content-class">
          <div className="routine-form">< RoutineForm setPercentage={setPercentage}/></div>
          <div className="prayer-form"><PrayerForm setPercentage={setPercentage}/></div>
        </div>
        <div className="left-content-class">
          <div className="Img">
            <img src={image1} alt="" />
          </div>
          <div className="Img">
            <img src={image2} alt="" />
          </div>
          <div className="Img">
            <img src={image3} alt="" />
          </div>
        </div>
      </div>
      <div className="divColor2"></div>
    </div>
  );
};

export default HomePage;
