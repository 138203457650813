import React, { useState, useEffect } from "react";
import "./GoalsAndSunnah.css";
import image1 from "./IMG/Group 13.png";
import image2 from "./IMG/Group 14.png";
import axios from "axios";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const GoalsAndSunnah = ({ setPercentage }) => {
  const [tasks, setTasks] = useState({
    akinShipRelationship: false,
    charity: false,
    parentalConnection: false,
    fruits: false,
    vegetablesAndLeaves: false,
    reducingTheAmountOfSugars: false,
    nuts: false,
    freshGuice: false,
    drinkWater: false,
  });

  const saveTasksToLocalStorage = (tasks) => {
    localStorage.setItem("GoalsAndSunnah", JSON.stringify(tasks));
  };

  const loadTasksFromLocalStorage = () => {
    const savedTasks = localStorage.getItem("GoalsAndSunnah");
    return savedTasks ? JSON.parse(savedTasks) : null;
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks, [id]: checked };
      saveTasksToLocalStorage(updatedTasks);
      return updatedTasks;
    });
  };


  const handleSubmit = async () => {
    const taskData = [
      {
        api: "https://muslim-dairy.alyoumsa.com/api/tasks/create/9", 
        tasks: [
          { task_id: 64, completed: tasks.fruits ? 1 : 0 },
          { task_id: 65, completed: tasks.vegetablesAndLeaves ? 1 : 0 },
          { task_id: 66, completed: tasks.reducingTheAmountOfSugars ? 1 : 0 },
          { task_id: 67, completed: tasks.nuts ? 1 : 0 },
          { task_id: 68, completed: tasks.freshGuice ? 1 : 0 },
          { task_id: 69, completed: tasks.drinkWater ? 1 : 0 },
        ],
      },
      {
        api: "https://muslim-dairy.alyoumsa.com/api/tasks/create/10",
        tasks: [
          { task_id: 70, completed: tasks.akinShipRelationship ? 1 : 0 },
          { task_id: 71, completed: tasks.charity ? 1 : 0 },
          { task_id: 72, completed: tasks.parentalConnection ? 1 : 0 },
        ],
      },
    ];
    try {
      for (const goalsAndSunnah of taskData) {
        const formGoalsAndSunnah  = new FormData();
        goalsAndSunnah.tasks.forEach((task, index) => {
          formGoalsAndSunnah.append(`tasks[${index}][task_id]`, task.task_id);
          formGoalsAndSunnah.append(
            `tasks[${index}][completed]`,
            task.completed
          );
        });

        console.log("Sending data to:", goalsAndSunnah.api);
        const response = await axios.post(goalsAndSunnah.api,formGoalsAndSunnah, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        });
        const totalPercentage = response.data.total_percentage;
        setPercentage(totalPercentage);
      }

      alert("Goal / Sunnah submitted successfully!");
    } catch (error) {
      console.error("Error submitting tasks:", error);
      alert("Failed to submit tasks.");
    }
  };

  useEffect(() => {
    const fetchGoalandSunnah = async () => {
      try {
        const [responseSideTasks, responseRewardTasks] = await Promise.all([
          axios.get(
            "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/9",
            {
              headers: { Authorization: `Bearer ${getCookie("token")}` },
            }
          ),
          axios.get(
            "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/10",
            {
              headers: { Authorization: `Bearer ${getCookie("token")}` },
            }
          ),
        ]);

        console.log("Fetched data from responseSideTasks:", responseSideTasks.data);
        console.log("Fetched data from responseRewardTasks:", responseRewardTasks.data);
        const fetchedTasks = {
          fruits:
          responseSideTasks.data.tasks.find((task) => task.task_id === 64)
            ?.completed === 1,
        vegetablesAndLeaves:
          responseSideTasks.data.tasks.find((task) => task.task_id === 65)
            ?.completed === 1,
        reducingTheAmountOfSugars:
          responseSideTasks.data.tasks.find((task) => task.task_id === 66)
            ?.completed === 1,
        nuts:
          responseSideTasks.data.tasks.find((task) => task.task_id === 67)
            ?.completed === 1,
        freshGuice:
          responseSideTasks.data.tasks.find((task) => task.task_id === 68)
            ?.completed === 1,
        drinkWater:
          responseSideTasks.data.tasks.find((task) => task.task_id === 69)
            ?.completed === 1,
        akinShipRelationship:
          responseRewardTasks.data.tasks.find((task) => task.task_id === 70)
            ?.completed === 1,
        charity:
          responseRewardTasks.data.tasks.find((task) => task.task_id === 71)
            ?.completed === 1,
        parentalConnection:
          responseRewardTasks.data.tasks.find((task) => task.task_id === 72)
            ?.completed === 1,
        };

        const savedTasks = loadTasksFromLocalStorage();
        setTasks(savedTasks || fetchedTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };
    fetchGoalandSunnah();
  }, []);

  return (
    <>
      <div className="container">
        <div
          className="section"
          style={{ backgroundColor: "rgb(197 136 151 / 20%)" }}
        >
          <h3>الأجور</h3>
          <label>
            <input
              type="checkbox"
              id="akinShipRelationship"
              checked={tasks.akinShipRelationship}
              onChange={handleCheckboxChange}
            />
            صلة أرحام
          </label>
          <label>
            <input
              type="checkbox"
              id="charity"
              checked={tasks.charity}
              onChange={handleCheckboxChange}
            />
            صدقة
          </label>
          <label>
            <input
              type="checkbox"
              id="parentalConnection"
              checked={tasks.parentalConnection}
              onChange={handleCheckboxChange}
            />
            صلة الوالدين
          </label>
        </div>

        <div
          className="section"
          style={{ backgroundColor: "rgb(203 0 255 / 2%)" }}
        >
          <h3>الجانبية</h3>
          <label>
            <input
              type="checkbox"
              id="fruits"
              checked={tasks.fruits}
              onChange={handleCheckboxChange}
            />
            فواكه
          </label>
          <label>
            <input
              type="checkbox"
              id="vegetablesAndLeaves"
              checked={tasks.vegetablesAndLeaves}
              onChange={handleCheckboxChange}
            />
            خضروات وورقيات
          </label>
          <label>
            <input
              type="checkbox"
              id="reducingTheAmountOfSugars"
              checked={tasks.reducingTheAmountOfSugars}
              onChange={handleCheckboxChange}
            />
            تقليل كمية السكريات
          </label>
          <label>
            <input
              type="checkbox"
              id="nuts"
              checked={tasks.nuts}
              onChange={handleCheckboxChange}
            />
            مكسرات
          </label>
          <label>
            <input
              type="checkbox"
              id="freshGuice"
              checked={tasks.freshGuice}
              onChange={handleCheckboxChange}
            />
            عصير طازجة
          </label>
          <label>
            <input
              type="checkbox"
              id="drinkWater"
              checked={tasks.drinkWater}
              onChange={handleCheckboxChange}
            />
            شرب ماء من 8 الى 10 كاسات
          </label>
        </div>

        <div className="images">
          <img src={image2} alt="food" className="image" />
        </div>

        <div className="images">
          <img src={image1} alt="moon" className="image" />
        </div>
      </div>

      <button className="submit-button-Goals" onClick={handleSubmit}>
        إرسال
      </button>
    </>
  );
};

export default GoalsAndSunnah;
