import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./RegistrationForm.css";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (formData.password !== formData.password_confirmation) {
      setError("Password do not match Confirm Password");
      return;
    }

    const { name, email, password, password_confirmation } = formData;
    // const csrfToken = document
    //   .querySelector('meta[name="csrf-token"]')
    //   ?.getAttribute("content");
    try {
      const response = await fetch(
        "https://muslim-dairy.alyoumsa.com/api/user/register?lang=en",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "X-CSRF-TOKEN": csrfToken,
          },
          body: JSON.stringify({
            name,
            email,
            password,
            password_confirmation,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage("Account created successfully!");
        setFormData({
          name: "",
          email: "",
          password: "",
          password_confirmation: "",
        });
        localStorage.clear();
        navigate("/login");
      } else {
        setError(data.message || "Error creating account");
      }
    } catch (err) {
      console.log(err);
      console.log("err");
      setError("An error occurred while registering. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="register-container">
      <form onSubmit={handleSubmit} className="register-form"> 
        <h2>انشاء حساب جديد</h2>

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <div className="form-group">
          <label>
            الاسم:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            البريد الالكتروني:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group" style={{ position: "relative" }}>
          <label>
            كلمة المرور:
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {formData.password && (
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                className="toggle-password"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              />
            )}
          </label>
        </div>
        <div className="form-group" style={{ position: "relative" }}>
          <label>
            تأكيد كلمة المرور:
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleChange}
              required
            />
            {formData.password_confirmation && (
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                onClick={toggleConfirmPasswordVisibility}
                className="toggle-password"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              />
            )}
          </label>
        </div>
        <button type="submit" className="register-button">
          تسجيل
        </button>
        <Link to="/Login" style={{textDecoration:"none",color:"blue"}}>
         تسجيل الدخول
        </Link>
      </form>
    </div>
  );
};

export default RegistrationForm;
