import React, { useState, useEffect } from "react";
import "./DailyChecklist.css";
import axios from "axios";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const DailyChecklist = ({ setPercentage }) => {
  const [tasks, setTasks] = useState({
    suratAlMulk: false,
    readAdhkar: false,
    earlySleep: false,
    earlyWakeup: false,
    alBaqarah: false,
    yaseen: false,
    alWaqiah: false,
  });

  const saveTasksToLocalStorage = (tasks) => {
    localStorage.setItem("dailyTasks", JSON.stringify(tasks));
  };

  const loadTasksFromLocalStorage = () => {
    const savedTasks = localStorage.getItem("dailyTasks");
    return savedTasks ? JSON.parse(savedTasks) : null;
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks, [id]: checked };
      saveTasksToLocalStorage(updatedTasks);
      return updatedTasks;
    });
  };

  const handleSubmit = async () => {
    const taskData = [
      {
        api: "https://muslim-dairy.alyoumsa.com/api/tasks/create/6", // القران
        tasks: [
          { task_id: 57, completed: tasks.alBaqarah ? 1 : 0 },
          { task_id: 58, completed: tasks.yaseen ? 1 : 0 },
          { task_id: 59, completed: tasks.alWaqiah ? 1 : 0 },
        ],
      },
      {
        api: "https://muslim-dairy.alyoumsa.com/api/tasks/create/7", // اوقات النوم
        tasks: [
          { task_id: 60, completed: tasks.earlySleep ? 1 : 0 },
          { task_id: 61, completed: tasks.earlyWakeup ? 1 : 0 },
        ],
      },
      {
        api: "https://muslim-dairy.alyoumsa.com/api/tasks/create/8", // اذكار النوم
        tasks: [
          { task_id: 62, completed: tasks.suratAlMulk ? 1 : 0 },
          { task_id: 63, completed: tasks.readAdhkar ? 1 : 0 },
        ],
      },
    ];
    try {
      for (const category of taskData) {
        const formDailyChecklist = new FormData();
        category.tasks.forEach((task, index) => {
          formDailyChecklist.append(`tasks[${index}][task_id]`, task.task_id);
          formDailyChecklist.append(
            `tasks[${index}][completed]`,
            task.completed
          );
        });
        console.log(`Sending data to API: ${category.api}`, [...formDailyChecklist]);

        const response = await axios.post(category.api, formDailyChecklist, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        });
        console.log("Daily Checklist Response from API:", response.data);
        const totalPercentage = response.data.total_percentage;
        setPercentage(totalPercentage);
      }

      alert("Daily Checklist submitted successfully!");
    } catch (error) {
      console.error("Error submitting tasks:", error);
      alert("Failed to submit tasks.");
    }
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const [responseQuran, responseSleep, responseAdhkar] =
          await Promise.all([
            axios.get(
              "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/6",
              {
                headers: { Authorization: `Bearer ${getCookie("token")}` },
              }
            ),
            axios.get(
              "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/7",
              {
                headers: { Authorization: `Bearer ${getCookie("token")}` },
              }
            ),
            axios.get(
              "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/8",
              {
                headers: { Authorization: `Bearer ${getCookie("token")}` },
              }
            ),
          ]);

          console.log("Fetched Quran Tasks:", responseQuran.data);
          console.log("Fetched Sleep Tasks:", responseSleep.data);
          console.log("Fetched Adhkar Tasks:", responseAdhkar.data);

        const fetchedTasks = {
          alBaqarah:
            responseQuran.data.tasks.find((task) => task.task_id === 57)
              ?.completed === 1,
          yaseen:
            responseQuran.data.tasks.find((task) => task.task_id === 58)
              ?.completed === 1,
          alWaqiah:
            responseQuran.data.tasks.find((task) => task.task_id === 59)
              ?.completed === 1,
          earlySleep:
            responseSleep.data.tasks.find((task) => task.task_id === 60)
              ?.completed === 1,
          earlyWakeup:
            responseSleep.data.tasks.find((task) => task.task_id === 61)
              ?.completed === 1,
          suratAlMulk:
            responseAdhkar.data.tasks.find((task) => task.task_id === 62)
              ?.completed === 1,
          readAdhkar:
            responseAdhkar.data.tasks.find((task) => task.task_id === 63)
              ?.completed === 1,
        };

        const savedTasks = loadTasksFromLocalStorage();
        setTasks(savedTasks || fetchedTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
        console.log("Failed to fetch daily tasks.");
      }
    };

    fetchTasks();
  }, []);

  return (
    <>
      <div className="checklist-container">
        <div className="checklist-box">
          <h2 className="checklist-title">أذكار النوم</h2>
          <div className="checklist-items">
            <div className="checklist-item">
              <label htmlFor="suratAlMulk">قراءة سورة الملك</label>
              <input
                type="checkbox"
                id="suratAlMulk"
                checked={tasks.suratAlMulk}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="checklist-item">
              <label htmlFor="readAdhkar">قراءة الأذكار</label>
              <input
                type="checkbox"
                id="readAdhkar"
                checked={tasks.readAdhkar}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>

        <div className="checklist-box">
          <h2 className="checklist-title">أوقات النوم</h2>
          <div className="checklist-items">
            <div className="checklist-item">
              <label htmlFor="earlySleep">النوم مبكرًا</label>
              <input
                type="checkbox"
                id="earlySleep"
                checked={tasks.earlySleep}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="checklist-item">
              <label htmlFor="earlyWakeup">الاستيقاظ مبكرًا</label>
              <input
                type="checkbox"
                id="earlyWakeup"
                checked={tasks.earlyWakeup}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>

        <div className="checklist-box">
          <h2 className="checklist-title">قراءة القرآن</h2>
          <div className="checklist-items">
            <div className="checklist-item">
              <label htmlFor="alBaqarah">البقرة</label>
              <input
                type="checkbox"
                id="alBaqarah"
                checked={tasks.alBaqarah}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="checklist-item">
              <label htmlFor="yaseen">يس</label>
              <input
                type="checkbox"
                id="yaseen"
                checked={tasks.yaseen}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="checklist-item">
              <label htmlFor="alWaqiah">الواقعة</label>
              <input
                type="checkbox"
                id="alWaqiah"
                checked={tasks.alWaqiah}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>
      </div>
      <button className="submit-button-checklist" onClick={handleSubmit}>
        إرسال
      </button>
      <div className="divColor"></div>
    </>
  );
};

export default DailyChecklist;
