import React, { useState, useEffect } from 'react';
import './NotesSection.css'; 
import axios from 'axios';


function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const NotesSection = () => {
  const [notes, setNotes] = useState(Array(6).fill(""));

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get('https://muslim-dairy.alyoumsa.com/api/notes/show-notes', {
          headers: {
            Authorization: `Bearer ${getCookie("token")}`,
          },
        });

        console.log("API response:", response.data);
        console.log("Notes response:", response.data.notes);

        if (response.data && typeof response.data.notes === 'string') {
          const fetchedNotes = [response.data.notes]; 
          const initializedNotes = Array(6).fill("").map((_, i) => fetchedNotes[i] || ""); 
          setNotes(initializedNotes);  
        } else {
          console.error("No notes found in API response");
        }
  
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };
  
    fetchNotes(); 
  }, []);  

  const handleNoteChange = async (index, event) => {
    const newNotes = [...notes]; 
    newNotes[index] = event.target.value; 
    setNotes(newNotes);

    try {
      const response = await axios.post(
        'https://muslim-dairy.alyoumsa.com/api/notes/create-notes',
        {
          notes: newNotes[index],
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      console.log("Note saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };

  return (
    <div className="notes-container">
      <div className="notes-title">
        <h3 style={{ textAlign: "center" }}>ملاحظات</h3>
        <hr />
      </div>
      <div className="notes-lines">
        {notes.map((note, index) => (
          <textarea 
            key={index} 
            value={note || ""}  
            onChange={(event) => handleNoteChange(index, event)} 
            className="notes-textarea" 
            placeholder={``}
          />
        ))}
      </div>
    </div>
  );
};

export default NotesSection;
