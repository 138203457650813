import React, { useState, useEffect } from "react";
import "./TaskList.css";
import axios from "axios";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const TaskList = () => {
  const [list, setList] = useState(Array(6).fill(""));

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('https://muslim-dairy.alyoumsa.com/api/notes/show-lists', {
          headers: {
            Authorization: `Bearer ${getCookie("token")}`,
          },
        });

        console.log("API response:", response.data);
        console.log("Lists response:", response.data.notes);

        if (response.data && typeof response.data.notes === 'string') {
          const fetchedTasks =  [response.data.notes];
        const initializedTasks =Array(6).fill("").map((_, i) => fetchedTasks[i] || "");
        setList(initializedTasks);
        } else {
          console.error("No Lists found in API response");
        }
  
      } catch (error) {
        console.error("Error fetching lists:", error);
      }
    };
  
    fetchTasks(); 
  }, []); 

  
  const handleNoteChange = async (index, event) => {
    const newList = [...list];
    newList[index] = event.target.value;
    setList(newList);

    try {
      const response = await axios.post(
        "https://muslim-dairy.alyoumsa.com/api/notes/create-lists",
        {
          notes:newList[index],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      console.log("Task saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };
  return (
    <div className="list-container">
      <div className="list-title">
        <h3 style={{ textAlign: "center" }}>قائمة المهام</h3>
        <hr />
      </div>
      <div className="list-lines">
        {list.map((task, index) => (
          <textarea
            key={index}
            value={task}
            onChange={(event) => handleNoteChange(index, event)}
            className="list-textarea"
          />
        ))}
      </div>
    </div>
  );
};

export default TaskList;
