import React, { useState, useEffect } from "react";
import "./HomePage.css";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import Cookies from "js-cookie";

const prayers = [
  { id: "fajr", label: "الفجر" },
  { id: "duha", label: "الضحى" },
  { id: "dhuhr", label: "الظهر" },
  { id: "asr", label: "العصر" },
  { id: "maghrib", label: "المغرب" },
  { id: "isha", label: "العشاء" },
  { id: "witr", label: "الوتر" },
  { id: "night", label: "قيام الليل" },
];

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const PrayerForm = ({ setPercentage }) => {
  const [formData, setFormData] = useState(
    prayers.reduce((acc, prayer) => {
      acc[prayer.id] = {
        fard: false,
        jamaah: false,
        takbeer: false,
        sunnah: false,
      };
      return acc;
    }, {})
  );
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const savedData = localStorage.getItem("prayerFormData");
        if (savedData) {
          setFormData(JSON.parse(savedData));
          setLoading(false);
          return;
        }
        const response = await axios.get(
          "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/2",
          {
            headers: {
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );

        const tasks = response.data.tasks;
        console.log("Fetched prayer tasks:", tasks); 

        const updatedData = { ...formData };

        tasks.forEach((task) => {
          const taskId = task.task_id;
          const completed = task.completed === 1;

          // Task IDs 8 to 39
          if (taskId >= 8 && taskId <= 39) {
            const prayerId = prayers[Math.floor((taskId - 8) / 4)].id;
            const taskType = (taskId - 8) % 4;

            if (taskType === 0) updatedData[prayerId].fard = completed;
            if (taskType === 1) updatedData[prayerId].jamaah = completed;
            if (taskType === 2) updatedData[prayerId].takbeer = completed;
            if (taskType === 3) updatedData[prayerId].sunnah = completed;
          }
        });

        setFormData(updatedData);
        setLoading(false);
      } catch (err) {
        setError("Error fetching prayer data");
        console.error("Error:", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []); 

  const handleChange = (e, prayerId, field) => {
    const updatedData = {
      ...formData,
      [prayerId]: {
        ...formData[prayerId],
        [field]: e.target.checked,
      },
    };
    setFormData(updatedData);
    localStorage.setItem("prayerFormData", JSON.stringify(updatedData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tasks = [];

    prayers.forEach((prayer, prayerIndex) => {
      const prayerData = formData[prayer.id];
      tasks.push({ task_id: prayerIndex * 4 + 8, completed: prayerData.fard ? 1 : 0 });
      tasks.push({ task_id: prayerIndex * 4 + 9, completed: prayerData.jamaah ? 1 : 0 });
      tasks.push({ task_id: prayerIndex * 4 + 10, completed: prayerData.takbeer ? 1 : 0 });
      tasks.push({ task_id: prayerIndex * 4 + 11, completed: prayerData.sunnah ? 1 : 0 });
    });

    console.log("Data being sent:", tasks);
    try {
      const response = await axios.post(
        "https://muslim-dairy.alyoumsa.com/api/tasks/create/2",
        { tasks },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );

      const totalPercentage = response.data.total_percentage;
      setPercentage(totalPercentage);
      alert("Tasks successfully submitted!");

      localStorage.setItem("prayerFormData", JSON.stringify(formData));
    } catch (error) {
      console.error("Error submitting tasks:", error.response || error.message);
      alert("Error submitting tasks. Please try again.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h3>الصلوات والفرائض</h3>
      <div className="separator-line-prayer"></div>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="prayer-grid">
          {prayers.map((prayer) => (
            <div key={prayer.id} className="prayer-card">
              <h4>{prayer.label}</h4>
              <label>
                <input
                  type="checkbox"
                  checked={formData[prayer.id].fard}
                  onChange={(e) => handleChange(e, prayer.id, "fard")}
                />
                فرد
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData[prayer.id].jamaah}
                  onChange={(e) => handleChange(e, prayer.id, "jamaah")}
                />
                جماعة
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData[prayer.id].takbeer}
                  onChange={(e) => handleChange(e, prayer.id, "takbeer")}
                />
                تكبير
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData[prayer.id].sunnah}
                  onChange={(e) => handleChange(e, prayer.id, "sunnah")}
                />
                سنن
              </label>
            </div>
          ))}
        </div>
        <button type="submit">إرسال</button>
      </form>
    </>
  );
};

export default PrayerForm;
