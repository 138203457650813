import React, { useState, useEffect } from "react";
import "./HomePage.css";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import Cookies from "js-cookie";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const RoutineForm = ({ setPercentage}) => {
  const [formData, setFormData] = useState({
    wakeUpEarly: false,
    drinkMoreWater: false,
    exercise: false,
    dress: false,
    readGoal: false,
    meditate: false,
    stopUsingPhone: false,
  });
  const [error, setError] = useState(null);

  const saveToLocalStorage = (data) => {
    localStorage.setItem("routineFormData", JSON.stringify(data));
  };

  const loadFromLocalStorage = () => {
    const storedData = localStorage.getItem("routineFormData");
    return storedData ? JSON.parse(storedData) : null;
  };

  useEffect(() => {
    const fetchRoutineFormData= async () => {
      const localData = loadFromLocalStorage();
      if (localData !== null) {
        setFormData(localData);
        return;
      }

      const token = getCookie("token");
      if (!token) {
        console.error("No authentication token found.");
        setError("Authentication token not found. Please log in.");
        return;
      }

      try {
        const response = await axios.get(
          "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/1",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const tasks = response.data.tasks;
        console.log("Fetched Routine Data tasks:", tasks);

        const updatedFormData = {
          wakeUpEarly: tasks.some(
            (task) => task.task_id === 1 && task.completed === 1
          ),
          drinkMoreWater: tasks.some(
            (task) => task.task_id === 2 && task.completed === 1
          ),
          exercise: tasks.some(
            (task) => task.task_id === 3 && task.completed === 1
          ),
          dress: tasks.some(
            (task) => task.task_id === 4 && task.completed === 1
          ),
          readGoal: tasks.some(
            (task) => task.task_id === 5 && task.completed === 1
          ),
          meditate: tasks.some(
            (task) => task.task_id === 6 && task.completed === 1
          ),
          stopUsingPhone: tasks.some(
            (task) => task.task_id === 7 && task.completed === 1
          ),
        };

        setFormData(updatedFormData);
        saveToLocalStorage(updatedFormData);
      } catch (err) {
        console.error("Error fetching Routine data:", err);
        setError(
          "Failed to fetch Routine data. Please try again later."
        );
      }
    };

    fetchRoutineFormData();
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    const updatedData = {
      ...formData,
      [name]: checked,
    };
    setFormData(updatedData);
    saveToLocalStorage(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const routineTasks = new FormData();

    const tasks = [
      { task_id: 1, completed: formData.wakeUpEarly ? 1 : 0 },
      { task_id: 2, completed: formData.drinkMoreWater ? 1 : 0 },
      { task_id: 3, completed: formData.exercise ? 1 : 0 },
      { task_id: 4, completed: formData.dress ? 1 : 0 },
      { task_id: 5, completed: formData.readGoal ? 1 : 0 },
      { task_id: 6, completed: formData.meditate ? 1 : 0 },
      { task_id: 7, completed: formData.stopUsingPhone ? 1 : 0 },
    ];

    tasks.forEach((task, index) => {
      routineTasks.append(`tasks[${index}][task_id]`, task.task_id);
      routineTasks.append(`tasks[${index}][completed]`, task.completed);
    });

    console.log("Sending routine tasks:", tasks); 
    const token = getCookie("token");
    if (!token) {
      console.error("No authentication token found.");
      setError("Authentication token not found. Please log in.");
      return;
    }

    try {
      const response = await axios.post(
        "https://muslim-dairy.alyoumsa.com/api/tasks/create/1",
        routineTasks,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const totalPercentage = response.data.total_percentage;
      setPercentage(totalPercentage);
      console.log("Response:", response.data);
      console.log("totalPercentage:", totalPercentage);
      alert("Tasks for routine successfully submitted!");
    } catch (error) {
      console.error("Error submitting tasks:", error.response || error.message);
      setError("Error submitting tasks. Please try again.");
      alert("Error submitting tasks. Please try again.");
    }
  };
  return (
    <>
      <h3>روتين بداية اليوم</h3>
      <div className="separator-line-routine"></div>
      <form onSubmit={handleSubmit}>
        <div className="checkbox-container">
          <div>
            <label>
              <input
                type="checkbox"
                name="wakeUpEarly"
                checked={formData.wakeUpEarly}
                onChange={handleChange}
              />
              الاستيقاظ مبكرًا
            </label>
            <label>
              <input
                type="checkbox"
                name="drinkMoreWater"
                checked={formData.drinkMoreWater}
                onChange={handleChange}
              />
              شرب المياه بكثرة
            </label>
            <label>
              <input
                type="checkbox"
                name="exercise"
                checked={formData.exercise}
                onChange={handleChange}
              />
              التمارين
            </label>
            <label>
              <input
                type="checkbox"
                name="dress"
                checked={formData.dress}
                onChange={handleChange}
              />
              لمدة 20 دقيقة E دريس
            </label>
          </div>
          <div style={{ marginRight: "50px" }}>
            <label>
              <input
                type="checkbox"
                name="stopUsingPhone"
                checked={formData.stopUsingPhone}
                onChange={handleChange}
              />
              إيقاف استخدام الجوال فترة
            </label>
            <label>
              <input
                type="checkbox"
                name="readGoal"
                checked={formData.readGoal}
                onChange={handleChange}
              />
              قراءة كتاب هدف
            </label>
            <label>
              <input
                type="checkbox"
                name="meditate"
                checked={formData.meditate}
                onChange={handleChange}
              />
              التأمل والامتنان
            </label>
          </div>
        </div>
        <button type="submit">إرسال</button>
      </form>
    </>
  );
};

export default RoutineForm;
