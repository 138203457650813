import React, { useState, useEffect } from "react";
import "./DailyWork.css";
import Cookies from "js-cookie";

function getCookie(name) {
  return Cookies.get(name);
}

const DailyWork = ({ setPercentage }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const saveToLocalStorage = (value) => {
    localStorage.setItem("dailyWorkCompleted", JSON.stringify(value));
  };

  const loadFromLocalStorage = () => {
    const storedValue = localStorage.getItem("dailyWorkCompleted");
    return storedValue ? JSON.parse(storedValue) : null;
  };

  useEffect(() => {
    const fetchDailyWork = async () => {
      const localData = loadFromLocalStorage();
      if (localData !== null) {
        setIsCompleted(localData);
        setLoading(false);
        return;
      }

      const token = getCookie("token");
      if (!token) {
        console.error("No authentication token found.");
        setError("Authentication token not found. Please log in.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          "https://muslim-dairy.alyoumsa.com/api/tasks/get-dailyPlan-tasks/12",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching data: ${response.status} ${response.statusText}`
          );
        }

        const result = await response.json();
        console.log("Fetched Daily Work data:", result);

        const task = result.tasks.find((task) => task.task_id === 85);
        if (task) {
          setIsCompleted(task.completed === 1);
          saveToLocalStorage(task.completed === 1);
        } else {
          console.warn("Task with ID 85 not found.");
        }
      } catch (err) {
        console.error("Error fetching Daily Work data:", err);
        setError("Failed to fetch Daily Work data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchDailyWork();
  }, []);

  const handleCheckboxChange = async (e) => {
    const checked = e.target.checked;
    setIsCompleted(checked);
    saveToLocalStorage(checked);
  
    const data = {
      tasks: [
        {
          task_id: 85,
          completed: checked ? 1 : 0,
        },
      ],
    };

    console.log("Data being sent to API:", data);
    const token = getCookie("token");
    if (!token) {
      console.error("No authentication token found.");
      setError("Authentication token not found. Please log in.");
      return;
    }
  
    try {
      const response = await fetch(
        "https://muslim-dairy.alyoumsa.com/api/tasks/create/12",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
  
      if (!response.ok) {
        throw new Error(
          `Failed to update task: ${response.status} ${response.statusText}`
        );
      }
  
      const result = await response.json();
       console.log("Response from API after sending:", result);
  
       if (typeof result.total_percentage !== "undefined") {
        const totalPercentage = result.total_percentage;
        setPercentage(totalPercentage);
        console.log("Daily Work updated successfully:", result);
        alert("Daily Work successfully submitted!");
      } else {
        console.warn("total_percentage not found in response.");
      }
    } catch (error) {
      console.error("Error updating task:", error);
      setError("فشل في تحديث العمل اليومي. يرجى المحاولة مرة أخرى.");
      setIsCompleted(!checked);
      saveToLocalStorage(!checked);
    }
  };
  

  if (loading) {
    return <div className="daily-work-container">Loading...</div>;
  }

  return (
    <div className="daily-work-container">
      <div className="daily-work-header">
        <span className="work-label">العمل اليومي</span>
        <div className="horizontal-line"></div>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="daily-work-content">
        <input
          type="checkbox"
          className="work-checkbox"
          checked={isCompleted}
          onChange={handleCheckboxChange}
        />
        <span className="work-description">أكثر من 5 ساعات</span>
      </div>
    </div>
  );
};

export default DailyWork;
