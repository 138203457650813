import "./MainPage.css";
import HomePage from "./component/HomePage";
import DailyRoutine from "./component/DailyRoutine";
import Meals from "./component/Meals";
import DailyTasks from "./component/DailyTasks";
import DailyChecklist from "./component/DailyChecklist";
import GoalsAndSunnah from "./component/GoalsAndSunnah";
import NotesSection from "./component/NotesSection";
import TaskList from "./component/TaskList";
import FoodSelection from "./component/FoodSelection";
import DailyWork from "./component/DailyWork";
import GoalCards from "./component/GoalCards";
import ProtectedRoute from "./component/PRotectedRoute";
import { useState} from "react";
function MainPage() {
  const [percentage, setPercentage] = useState(0);
  return (
    <>
      <ProtectedRoute>
      <HomePage setPercentage={setPercentage}/>
      </ProtectedRoute>
      <DailyRoutine setPercentage={setPercentage} />
      <Meals setPercentage={setPercentage} />
      <DailyTasks setPercentage={setPercentage} />
      <DailyChecklist setPercentage={setPercentage} />
      <GoalsAndSunnah setPercentage={setPercentage} />
      <NotesSection setPercentage={setPercentage} />
      <TaskList setPercentage={setPercentage} />
      <FoodSelection setPercentage={setPercentage} />
      <DailyWork setPercentage={setPercentage} />
      <GoalCards setPercentage={setPercentage} percentage={percentage} />
    </>
  );
}

export default MainPage;
