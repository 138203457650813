import React, { useState, useEffect } from "react";
import "./GoalCards.css";
import image1 from "./IMG/Group 16.png";
import image2 from "./IMG/Group 17.png";
import axios from "axios";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const GoalCards = ({ setPercentage, percentage }) => {
  const fetchPercentage = async () => {
    try {
      const token = getCookie("token");
      const response = await axios.get(
        "https://muslim-dairy.alyoumsa.com/api/tasks/get-percentage",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log(response.data);
      if (response.data && response.data.total_percentage !== undefined) {
        const totalPercentage = response.data.total_percentage;
        
        setPercentage(totalPercentage);
        localStorage.setItem('savedPercentage', totalPercentage);
        
        console.log(totalPercentage);
      } else {
        console.warn("No valid data found in API response.");
        setPercentage(0);
      }
    } catch (error) {
      console.error("Error fetching percentage:", error);
    }
  };
  
  useEffect(() => {
    const savedPercentage = localStorage.getItem('savedPercentage');
    // if (savedPercentage) {
    //   setPercentage(parseFloat(savedPercentage)); 
    // } else {
      fetchPercentage();
    // }
  }, []); 

  return (
    <div className="card-container">
      <div className="goal-card">
        <div className="card-header" style={{ direction: "rtl" }}>
          <span className="goal-title">حقق أهدافك</span>
        </div>
        <img className="goal-image" src={image1} alt="Achieve your goals" />
      </div>
      <div className="goal-card">
        <div className="card-header">
          <span className="goal-percentage">
            {parseFloat(percentage).toFixed(2)}%
          </span>
          <span className="goal-title">نسبة الانجاز</span>
        </div>
        <img className="goal-image" src={image2} alt="Achievement percentage" />
      </div>
    </div>
  );
};

export default GoalCards;
